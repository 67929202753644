import React, { forwardRef } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentOrganization = forwardRef(
  (
    { fillColor = '#212121', ...props }: { fillColor?: string } & SvgProps,
    ref
  ) => (
    <Svg
      width={24}
      height={24}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <Path
        d='M11.75 1.998a3.752 3.752 0 00-.75 7.43V11.5H7.75a2.25 2.25 0 00-2.25 2.25v.825a3.754 3.754 0 00.752 7.43A3.752 3.752 0 007 14.574v-.825a.75.75 0 01.75-.75h8a.75.75 0 01.75.75v.825a3.754 3.754 0 00.752 7.43 3.752 3.752 0 00.748-7.43v-.825a2.25 2.25 0 00-2.25-2.25H12.5V9.427a3.754 3.754 0 00-.75-7.43zM9.498 5.75a2.252 2.252 0 114.504 0 2.252 2.252 0 01-4.504 0zM4 18.252a2.252 2.252 0 114.505 0 2.252 2.252 0 01-4.505 0zM17.252 16a2.252 2.252 0 110 4.504 2.252 2.252 0 010-4.504z'
        fill={fillColor}
      />
    </Svg>
  )
)

export default FluentOrganization
